<template>
  <div class="container">
    <top-nav :banner="globalData.index.banner.imgList" :isHome="true">
      <template #digital>
        <div class="banner-digital">
          <div
            class="banner-item"
            v-for="(item, index) of globalData.index.banner.digital"
            :key="index"
          >
          <img :src="require(`../asset/index/${item.img || 'index-icon1'}.png`)" style="width: 90px;" alt="">
            <div class="num">
              {{ item.num }}<span v-if="item.unit" class="sp">小时</span>
              <div class="digital-txt">{{ item.txt }}</div>
            </div>
          </div>
        </div>
      </template>
    </top-nav>
    <div class="custom">
      <strip-title
        :title="globalData.index.custom.topic.title"
        :brief="globalData.index.custom.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.index.custom.list"
          :key="index"
        >
          <div
            class="img"
            :style="{
              'background-image': `url(${custom[index].img})`,
            }"
          >
            <img class="icon" :src="custom[index].icon" alt="" />
          </div>
          <div class="txt">
            <div class="title">{{ item.title }}</div>
            <div class="brief">{{ item.brief }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="core">
      <strip-title
        :title="globalData.index.core.topic.title"
        :subtitle="globalData.index.core.topic.subtitle"
        :brief="globalData.index.core.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.index.core.list"
          :key="index"
          @click="nav(index)"
        >
          <img
            class="icon"
            :src="index == 0 ? coreic1 : index == 1 ? coreic2 : coreic3"
            alt=""
          />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>
    <div class="promise content-limit">
      <strip-title
        :title="globalData.index.promise.topic.title"
        :subtitle="globalData.index.promise.topic.subtitle"
        :brief="globalData.index.promise.topic.brief"
      ></strip-title>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) of globalData.index.promise.list"
          :key="index"
        >
          <img
            class="icon"
            :src="
              index == 0
                ? proic1
                : index == 1
                ? proic2
                : index == 2
                ? proic3
                : proic4
            "
            alt=""
          />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>
    <div class="process">
      <strip-title
        :title="globalData.index.process.topic.title"
        :brief="globalData.index.process.topic.brief"
      ></strip-title>
      <serve-process class="content-limit"></serve-process>
    </div>
    <div class="advantage">
      <div class="content content-limit">
        <div class="left">
          <div class="title">{{ globalData.index.advantage.title }}</div>
          <div class="brief">{{ globalData.index.advantage.brief }}</div>
        </div>
        <div class="right">
          <div
            class="item"
            v-for="(item, index) of globalData.index.advantage.list"
            :key="index"
          >
            <img class="img" :src="advantage[index]" alt="" />
            <div class="inner">
              <div class="title">{{ item.title }}</div>
              <div class="brief">{{ item.brief }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="multiple content-limit">
      <strip-title
        :title="globalData.index.multiple.topic.title"
        :brief="globalData.index.multiple.topic.brief"
      ></strip-title>
      <img class="multiple-img" src="../asset/index/index_70.png" alt="" />
    </div>

    <div class="join">
      <strip-title
        :title="globalData.index.support.title"
        :brief="globalData.index.support.description"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.index.support.list"
          :key="index"
        >
          <div class="img">
            <img :src="support[index]" alt="" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="partner">
      <strip-title
        :title="globalData.index.partner.title"
        :brief="globalData.index.partner.description"
      ></strip-title>
      <div class="list content-limit">
        <div class="item pi1" v-for="(item, index) of partner1" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="list content-limit">
        <div class="item pi2" v-for="(item, index) of partner2" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="list content-limit">
        <div class="item pi1" v-for="(item, index) of partner3" :key="index">
          <img class="img" :src="item" alt="" />
        </div>
      </div>
      <div class="button">
        查看更多<img class="icon" src="../asset/index/index_60.png" alt="" />
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import { mapGetters, mapMutations } from "vuex";
import banner from "../asset/index/index_25.png";
import coreic1 from "../asset/index/index_32.png";
import coreic2 from "../asset/index/index_33.png";
import coreic3 from "../asset/index/index_31.png";
import arrow from "../asset/index/index_02.png";
import probg1 from "../asset/index/browser-minimize-2@2x.png";
import probg2 from "../asset/index/paragraph-left-2@2x.png";
import probg3 from "../asset/index/brightness-high-3@2x.png";
import probg4 from "../asset/index/pin@2x.png";
import proic1 from "../asset/index/index_2.png";
import proic2 from "../asset/index/index_4.png";
import proic3 from "../asset/index/index_3.png";
import proic4 from "../asset/index/index_5.png";
import Support from "./index/support.vue";
import ServeProcess from "../components/serve-process.vue";
import anime from "animejs/lib/anime.es.js";
import cusimg1 from "../asset/index/index_38.png";
import cusimg2 from "../asset/index/index_34.png";
import cusimg3 from "../asset/index/index_42.png";
import cusimg4 from "../asset/index/index_44.png";
import cusimg5 from "../asset/index/index_37.png";
import cusimg6 from "../asset/index/index_39.png";
import cusimg7 from "../asset/index/index_41.png";
import cusimg8 from "../asset/index/index_43.png";
import cusic1 from "../asset/index/index_49.png";
import cusic2 from "../asset/index/index_54.png";
import cusic3 from "../asset/index/index_50.png";
import cusic4 from "../asset/index/index_46.png";
import cusic5 from "../asset/index/index_29.png";
import cusic6 from "../asset/index/index_52.png";
import cusic7 from "../asset/index/index_53.png";
import cusic8 from "../asset/index/index_30.png";
import advic1 from "../asset/index/index_68.png";
import advic2 from "../asset/index/index_65.png";
import advic3 from "../asset/index/index_66.png";
import advic4 from "../asset/index/index_67.png";
import advic5 from "../asset/index/index_64.png";
import advic6 from "../asset/index/index_69.png";
import supic1 from "../asset/index/index_35.png";
import supic2 from "../asset/index/index_36.png";
import supic3 from "../asset/index/index_62.png";
import partic1 from "../asset/index/index_20.png";
import partic2 from "../asset/index/index_71.png";
import partic3 from "../asset/index/index_12.png";
import partic5 from "../asset/index/index_13.png";
import partic6 from "../asset/index/index_14.png";
import partic7 from "../asset/index/index_15.png";
import partic8 from "../asset/index/index_16.png";
import partic9 from "../asset/index/index_17.png";
import partic10 from "../asset/index/index_18.png";
import partic11 from "../asset/index/index_10.png";
import partic12 from "../asset/index/index_24.png";
import partic13 from "../asset/index/index_23.png";
import partic14 from "../asset/index/index_22.png";
import partic15 from "../asset/index/index_21.png";

export default {
  data() {
    return {
      banner,
      coreic1,
      coreic2,
      coreic3,
      arrow,
      probg1,
      probg2,
      probg3,
      probg4,
      proic1,
      proic2,
      proic3,
      proic4,
      custom: [
        {
          img: cusimg1,
          icon: cusic1,
        },
        {
          img: cusimg2,
          icon: cusic2,
        },
        {
          img: cusimg3,
          icon: cusic3,
        },
        {
          img: cusimg4,
          icon: cusic4,
        },
        {
          img: cusimg5,
          icon: cusic5,
        },
        {
          img: cusimg6,
          icon: cusic6,
        },
        {
          img: cusimg7,
          icon: cusic7,
        },
        {
          img: cusimg8,
          icon: cusic8,
        },
      ],
      advantage: [advic1, advic2, advic3, advic4, advic5, advic6],
      support: [supic1, supic2, supic3],
      partner1: [partic1, partic2, partic3, partic5, partic6],
      partner2: [partic7, partic8, partic9, partic10, partic11],
      partner3: [partic12, partic13, partic14, partic15, partic1],
    };
  },
  components: {
    Support,
    ServeProcess,
  },
  methods: {
    ...mapMutations({
      increase: "increment",
    }),
    nav(index) {
      let url = "/";
      switch (index) {
        case 0:
          url = "/development";
          break;
        case 1:
          url = "/development-mobile";
          break;
        case 2:
          url = "/development-applet";
          break;
      }
      this.$router.push(url);
    },
    toView(index) {
      let element = null;
      switch (index) {
        case 0:
          element = document.querySelector(".nonmotor");
          break;
        case 1:
          element = document.querySelector(".motor");
          break;
        case 2:
          element = document.querySelector(".life");
          break;
        case 3:
          element = document.querySelector(".face");
          break;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$nextTick(() => {
    // new WOW({
    //   live: false,
    // }).init();
    // })
    //走马灯
    let xTrans = [];
    anime.set(".partner .pi1", {
      translateX: function (el, i, l) {
        xTrans[i] = { x: i * 282 };
        return i * 282;
      },
    });

    anime({
      targets: xTrans,
      duration: 35000, //走一周持续时间
      easing: "linear",
      x: "+=1128",
      loop: true,
      update: function (anim) {
        anime.set(".partner .pi1", {
          translateX: function (el, i, l) {
            return xTrans[i].x % 1410;
          },
        });
      },
    });
    // 走马灯2;
    let xTranss = [];
    anime.set(".partner .pi2", {
      translateX: function (el, i, l) {
        xTranss[i] = { xx: i * 282 };
        return i * 282;
      },
    });

    anime({
      targets: xTranss,
      duration: 35000, //走一周持续时间
      easing: "linear",
      xx: "+=1410",
      loop: true,
      update: function (anim) {
        anime.set(".partner .pi2", {
          translateX: function (el, i, l) {
            return xTranss[i].xx % 1410;
          },
        });
      },
    });
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.banner-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  .banner-title-sp {
    color: #3384e8;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  margin-bottom: 77px;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
  }
}
.banner-txt {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px 4px 4px 4px;
  padding: 18px 27px;
  color: #3384e8;
  font-size: 24px;
  display: inline-block;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 16px;
  }
}
.banner-digital {
  background: #FFFFFF;
  box-shadow: 0px 3px 16px 0px rgba(136,131,131,0.18);
  border-radius: 10px;
  height: 160px;
  width: 90%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #000;
  padding: 0 65px;
  justify-content: space-between;
  .banner-item {
    display: flex;
    .num {
      color:  #0075FF;
      font-size: 36px;
      text-align: left;
      margin-left: 35px;
      font-weight: bold;
      .sp {
        font-size: 36px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    .digital-txt {
      padding-left: 6px;
      color: #000;
      font-size: 18px;
      box-sizing: border-box;
      margin-top: 12px;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 200px;
      height: 100px;
    }
  }
}

.core {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);

  .list {
    line-height: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 391px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 80px;
    gap: 56px;
    .item {
      flex: 1;
      background: white;
      box-shadow: 3px 5px 20px 1px #ddecfa;
      padding: 0 74px;
      text-align: center;
      padding-top: 82px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.2);
      }
      .icon {
        width: 90px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
      }
      .brief {
        font-size: 14px;
        color: #666666;
        line-height: 2;
        margin-top: 40px;
      }
    }
  }
}
.strip {
  width: 100%;
  height: 324px;
  background: #0f51fa;
  margin-top: -500px;
  @media (max-width: 1250px) {
    visibility: hidden;
  }
}
.promise {
  padding: 80px 0;

  .list {
    display: flex;
    align-items: stretch;
    margin-top: 60px;
    height: 314px;
    gap: 40px;
    .item {
      position: relative;
      flex: 1;
      background: white;
      transition: all 0.5s;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      text-align: center;
      padding-top: 60px;
      .background {
        width: 130%;
        margin-top: 100px;
        margin-left: 40px;
      }

      .icon {
        height: 100px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 16px;
      }

      .brief {
        font-size: 14px;
        color: #666666;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .item {
        flex: 1 1 50%;
        margin-bottom: 100px;
      }
    }
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      .item {
        flex: 0 1 90%;
        text-align: center;
      }
    }
  }
}
.process {
  background: rgba(221, 236, 250, 0.5);
  padding: 80px 0;
}
.advantage {
  background: #1a1a1a url(../asset/index/index_45.png) no-repeat center/cover;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0;
    .left {
      color: white;
      text-align: center;
      margin-right: 120px;
      flex-shrink: 0;
      .title {
        font-size: 36px;
        margin-bottom: 24px;
      }
      .brief {
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      gap: 0 3%;
      flex-wrap: wrap;
      align-content: center;
      .item {
        width: 31%;
        display: flex;
        align-items: center;
        background: white;
        margin-bottom: 60px;
        padding: 48px;
        box-sizing: border-box;
        &:nth-child(3) ~ .item {
          margin-bottom: 0;
        }
        .img {
          width: 70px;
          margin-right: 24px;
        }
        .inner {
          .title {
            font-size: 24px;
            color: #333333;
            margin-bottom: 20px;
            font-weight: 500;
          }
          .brief {
            font-size: 14px;
            color: #333333;
            line-height: 2;
          }
        }
      }
    }
  }
}
.multiple {
  padding: 80px 0;

  .multiple-img {
    width: 100%;
    margin-top: 40px;
  }
}
.custom {
  padding: 193px 0 65px;

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    margin-top: 60px;
    .item {
      width: 22.5%;
      margin-bottom: 40px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      .img {
        position: relative;
        background: no-repeat center/cover;
        height: 218px;

        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .txt {
        text-align: center;
        padding: 24px 0 17px;
        background: white;
        .title {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: bold;
        }
        .brief {
          font-size: 14px;
          padding: 0 20px;
          line-height: 2;
        }
      }
    }
  }
}
.join {
  background: rgba(221, 236, 250, 0.5);
  padding: 80px 0;
  .list {
    gap: 90px 0;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    .item {
      position: relative;
      padding: 22px 0 40px;
      background: white;
      width: 800px;
      box-sizing: border-box;
      &:nth-child(2) {
        align-self: flex-end;
        text-align: right;
        .img {
          left: auto;
          right: 0;
        }
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: white;
        box-shadow: 3px 5px 8px 1px rgba(165, 165, 165, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        padding: 0 152px;
        font-size: 28px;
        color: #3384e8;
      }
      .brief {
        font-size: 24px;
        color: #666666;
        padding: 0 40px;
        margin-top: 40px;
      }
    }
  }
}
.partner {
  padding: 80px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  &::after {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  .list {
    position: relative;
    height: 190px;
    margin-top: 50px;
    overflow: hidden;
    .item {
      position: absolute;
      width: 282px;
      height: 190px;
      .img {
        object-fit: contain;
      }
    }
  }
  .button {
    color: white;
    font-size: 24px;
    width: 217px;
    height: 61px;
    margin: 0 auto;
    margin-top: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    .icon {
      width: 30px;
    }
  }
}
</style>
