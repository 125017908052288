<template>
  <div class="project-case">
    <top-nav :banner="globalData.projectCase.imgList"> </top-nav>
    <div class="case-container">
      <strip-title
        :title="globalData.projectCase.cases.title"
        :brief="globalData.projectCase.cases.brief"
      ></strip-title>
      <div class="list-container">
        <div class="header">
          <div class="left-text">
            <img
              class="img"
              src="../asset/project-case/benchmark-img.png"
              alt=""
            />
            {{ globalData.projectCase.leftText }}
          </div>
          <div
            class="right-option"
            v-for="(t, i) in globalData.projectCase.titleList"
            :key="i"
          >
            <div
              class="items"
              :class="{ active: pick === i + 1 }"
              @click="changeTab(i + 1)"
            >
              {{ t }}
            </div>
          </div>
        </div>
        <div class="list-content">
          <div class="list-items" v-for="(t, i) in list" :key="i">
            <img
              :src="
                require(`../asset/project-case/${t.img || 'cases-img1'}.png`)
              "
              class="img"
              alt=""
            />
            <div class="info-container">
              <div class="info-box">
                <span>{{ t.title }}</span>
                <span>{{ t.typeName }}</span>
              </div>
              <div class="desc">{{ t.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <partner />
    <my-footer></my-footer>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import banner from "../asset/solution/n_sol_35.png";
import { mapGetters } from "vuex";
import partner from "./index/partner.vue";

export default {
  name: "projectCase",
  components: {
    partner,
  },
  data: function () {
    return {
      banner,
      pick: 1,
      list: [],
      source: [],
      currentTab: 0,
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  watch: {
    globalData(newValue, oldV) {
      if (newValue.projectCase && newValue.projectCase.list) {
        this.list = newValue.projectCase.list;
        this.source = newValue.projectCase.list;
      }
    },
    immediate: true,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query?.tab) {
      const tabIndex = Number(to.query?.tab);
      this.changeTab(tabIndex);
    }
    next();
  },
  mounted() {
    if (this.$route.query?.tab) {
      const tabIndex = Number(this.$route.query.tab);
      this.changeTab(tabIndex);
    }
    this.$nextTick(() => {
      this.list = this.globalData.projectCase.list;
      this.source = this.globalData.projectCase.list;
    });
    // this.$store.commit('setBannerIndex', 2);
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
  },
  methods: {
    changeTab(num) {
      this.pick = num;
      if (num > 1) {
        this.list = this.source.filter((it) => it.type === num);
      } else this.list = this.source;
    },
  },
};
</script>

<style scoped lang="scss">
$baseSize: 24;
@property --rotate {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: false;
}
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
.project-case {
  .banner-title {
    color: white;
    font-size: px2rem(48);
    font-weight: bold;
    margin-bottom: px2rem(64);
    color: rgba(51, 132, 232, 1);
  }
  .banner-subtitle {
    color: white;
    font-size: 24px;
    margin-bottom: px2rem(64);
    text-align: left;
    line-height: 2;
  }
  .banner-txt {
    background: #0f52fb;
    border-radius: 4px 4px 4px 4px;
    padding: px2rem(18) px2rem(27);
    color: white;
    font-size: px2rem(24);
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .project-case {
    .banner-title {
      font-size: 24px;
      margin-bottom: px2rem(32);
      text-align: center;
    }
    .banner-subtitle {
      width: 100%;
      font-size: 18px;
    }
  }
}

.case-container {
  padding: 70px 0 30px 0;
  background: rgba(221, 236, 250, 0.5);
  .list-container {
    margin-top: 24px;
    max-width: 1440px;
    margin: 0 auto;
    .header {
      background: white;
      border-radius: 10px;
      padding: 42px 47px 42px 42px;
      display: flex;
      align-items: center;
      .left-text {
        display: flex;
        align-items: center;
        color: #283b86;
        font-size: 18px;
        .img {
          width: 30px;
          margin-right: 12px;
        }
      }
      .right-option {
        flex: 1;
        display: flex;
        font-size: 18px;
        justify-content: space-between;
        margin-left: 46px;
        .items {
          cursor: pointer;
          padding: 15px 20px;
          &:hover {
            background: #0082ff;
            color: #fff;
          }
        }
        .active {
          background: #0082ff;
          color: #fff;
        }
      }
    }
    .list-content {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 25px;
      margin-top: 18px;
      .list-items {
        position: relative;
        transition: all 0.5s;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
        }
        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          z-index: -1;
          height: 100%;
          width: 100%;
          transform: scale(1.03);
          filter: blur(2px);
          opacity: 1;
          transition: opacity .5s; 
        }
        &:hover {
          transform: scale(1.02);
          &::before {
            background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #ffddc5);
            animation: spin 3s linear infinite;
          }
          &::after{ 
            background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #ffddc5);
            animation: spin 3s linear infinite;
          }
          // .img {
          //   transform: translateY(-15px);
          // }
        }
        @keyframes spin {
          0% {
            --rotate: 0deg;
          }
          100% {
            --rotate: 360deg;
          }
        }
      }
      .img {
        width: 100%;
        vertical-align: bottom;
      }
      .info-container {
        padding: 22px 0 32px 0;
        background: white;
      }
      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        & > span:first-child {
          font-size: 24px;
          // font-family: Microsoft YaHei;
        }
        & > span:last-child {
          font-size: 14px;
          color: #148cfc;
          background: #61abf17a;
          padding: 0px 10px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          border-radius: 15px;
        }
      }
      .desc {
        padding: 0 15px;
        margin-top: 25px;
        font-size: 16px;
        color: #8b8b8b;
        width: 425px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
