<template>
  <div id="root">
    <!-- 顶部导航 -->
    <!-- <top-nav :isIndex='$route.meta.isIndex' :title='$route.meta.title'></top-nav> -->

    <!-- <keep-alive>
			<router-view v-if='$route.meta.alive'></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.alive">
		     这里加载不被缓存的视图组件
		</router-view> -->
    <router-view></router-view>
    <scroll-to-top></scroll-to-top>
    <quotation-channel></quotation-channel>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("fetchData");
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}

.hideTxt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#root {
  margin: 0 auto;
  padding-bottom: 85px;
}
.content-limit {
  max-width: 1440px;
  margin: 0 auto;
}
</style>
